<template>
  <div id="app">
    <el-row class="dash-board">
      <el-col :span="6">
        <p class="in1">考试名称：</p>
        <el-input style="width: 200px" class="e-inps" v-model="testName"></el-input>
      </el-col>
      <el-col :span="3" style="display: flex;align-items: center;">
        <el-button type="primary" @click="getOne" class="inquire">查询</el-button>
        <el-button type="warning" @click="reset" class="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-row class="bg" :gutter="15" v-if="list.length>0">
      <el-col :span="8" v-for="(item,k) in list" :key="k" class="mag">
        <div class="box-card">
          <div class="info">
            <div class="paragraph">{{item.testName}}</div>
            <div class="paragraph fs14"><span>考试时长：{{item.totalTime}}  分钟</span><span>总分：{{item.totalScore}}</span></div>
            <div class="paragraph fs14">出卷人：{{item.operator}}</div>
            <div class="paragraph fs14">使用班级：{{item.courseName}}</div>
            <div class="bt">
              <el-button type="primary" v-if="item.testStatus==1" disabled>考试未开始</el-button>
              <el-button type="success" v-if="(item.testStatus==3 || item.testStatus==2) && item.isFinish == 1" @click="showTestScore(item)">查看成绩</el-button>
              <el-button type="success" v-if="item.testStatus==2 && item.isFinish == 0" @click="onefor(item)">进入考试</el-button>
              <el-button type="primary" v-if="(item.testStatus==3) && item.isFinish == 0" disabled>考试已结束</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-else style="padding: 20px; color: #999;background: #fff; margin-top: 20px;">没有数据</div>
    <el-dialog
      title="查看考试"
      :visible.sync="loking"
      width="30%"
      center>
      <el-tag>您的考试分数:</el-tag><el-tag type="success" style="margin-left: 20px">{{score}}分</el-tag>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="loking = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { findStuTestInfo, showTestScore } from '@/api/test.js'
  export default {
    data () {
      return {
        score: '0',
        isFinish: '',
        loking: false,
        total: 0,
        pageNum: 1,
        pageSize: 9,
        testName: '',
        status: '',
        list: [],
        query: {}
      }
    },
    mounted() {
      // this.findStuTestInfo() // 初始化考试信息
      this.getOne()
    },
    beforeDestroy() {
      window.onbeforeunload = function (e) {
        if (this.$route.name === '/Examinations') {
          return '关闭提示'
        } else {
          window.onbeforeunload = null
        }
      }
    },
    methods: {
      showTestScore(item) {
        this.loking = true
        const ModifyItem = item.id
        const classId = item.classId
        console.log(ModifyItem)
        showTestScore(ModifyItem, classId).then(resp => {
          this.score = resp.data.data
        })
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      },
      // findStuTestInfo() {
      //    this.query = {
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize,
      //     testName: this.testName
      //   }
      //   findStuTestInfo(this.query).then(resp => {
      //     this.total = resp.data.data.total // 查询总条数
      //     this.list = resp.data.data.rows // 接收返回的数据
      //   })
      // },
      onefor(item) {
        const ModifyItem = item.id
        this.$router.push({ path: '/test', query: { id: ModifyItem } })
        console.log(item.id)
      },
      getOne() {
        // this.findStuTestInfo()
         this.query = {
          userName: this.$store.getters.username,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          testName: this.testName
        }
        findStuTestInfo(this.query).then(resp => {
          if (resp.data.code === '200') {
            this.total = resp.data.data.total // 查询总条数
            this.list = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      reset() {
        this.testName = ''
      }
    }
  }
</script>

<style scoped>
  /* #app{background: #ffffff;} */
  .bg{margin-top: 15px; padding: 20px 10px; background-color: white;}
  .el-card__body{display: flex !important;}
  .box-card{ display: flex; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); padding: 20px 25px 20px;}
  .img{flex:0 0 140px; }
  .info{flex:1; padding-left: 15px; width: calc(100% - 140px); text-align: left;}
  .bt{ float:right; margin-top: 5px;}
  .paragraph{padding: 5px 0;line-height: 22px; overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
  .paragraph span{ padding-right: 25px;}
  .fs14{font-size: 14px;}
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
  }
  .mag{margin-bottom: 15px;margin-top: 15px;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 330px}
  .in3{width: 160px; position: absolute; top: 14px;left: 410px}
  .e-inps{position: absolute; left: 100px; top: 14px}
</style>
